import { FC } from 'react'

import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'

import { Button } from '@banx/components/Buttons'

import { ArrowDown } from '@banx/icons'
import { PATHS } from '@banx/router'

import styles from '../ProposalPage.module.less'

interface GeneralProposalInfoProps {
  title: string
  publicKey: string
}

//TODO: yeah I can do that so what

const publicKeyToContent = {
  Fm9Yrb45PoeN757jp2VjhETpyrDas9U11T9bjYi5VRRF: () => (
    // eslint-disable-next-line react/no-unescaped-entities
    <span>It's to settle down the most heated debate once and for all. Pick wisely.</span>
  ),
  BDqcWL84UZH6frUefto1oamsJzgMKeH6uxunLFPW69KD: () => (
    <span>
      <a href="https://discord.com/channels/897177095803920474/1092426879945932931/1331267215122501793">
        Full text of the proposal on Discord (click to read)
      </a>
    </span>
  ),
}

export const GeneralProposalInfo: FC<GeneralProposalInfoProps> = ({ title, publicKey }) => {
  const navigate = useNavigate()

  const goToBack = () => {
    navigate(PATHS.ROOT)
  }

  const ContentComponent = publicKeyToContent[publicKey as keyof typeof publicKeyToContent]

  return (
    <div className={classNames(styles.contentBlock, styles.proposalInfoBlock)}>
      <BackButton onClick={goToBack} />
      <div className={styles.proposalInfoContent}>
        <h2>{title}</h2>
        {ContentComponent ? <ContentComponent /> : <></>}
      </div>
    </div>
  )
}

const BackButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button
      type="standard"
      variant="tertiary"
      className={classNames(styles.backButton, styles.proposalInfoBlock)}
      onClick={onClick}
    >
      <ArrowDown />
      <span>Back</span>
    </Button>
  )
}
